<template>

	<div
		class="mt-30 text-left flex-row"
	>
		<MypageSide
			:program="program"
			:user="user"
		></MypageSide>

		<div
			class="flex-1 bg-white pa-24 radius-20"
		>
			<div>
				<div class="under-line-bbb pb-15 flex-row">
					<h3 class="flex-1 size-px-20">{{ program.title }}</h3>
				</div>

				<div class="mt-30 container">
					<div class="">

						<button
							@click="onTab('in')"
							class="btn-inline mr-10 radius-20 pa-13-30 font-weight-500"
							:class="tab_type == 'in' ? 'btn-primary' : 'btn-light-gray02'"
							style="outline: none;"
						>구독중</button>

						<button
							@click="onTab('out')"
							class="btn-inline  radius-20 pa-13-30 font-weight-500"
							:class="tab_type == 'out' ? 'btn-primary' : 'btn-light-gray02'"
							style="outline: none;"
						>구독 종료</button>
					</div>

					<div class="subindex_box_1">
						<ul
							class="mt-25 flex-row-wrap justify-space-between gap-20"
							v-if="items_member.length > 0"
						>
							<li
								v-for="(item, index) in items_member"
								:key="'item_' + index"
								class="justify-space-between items-center width-100"
								style="flex: 370px 1 0;"
								@click="toDetail(item)"
							>
								<div class="img-box-100 mr-10 radius-10"><img :src="item.subscrp_plan_img_url" @error="$bus.$emit('onErrorImage', $event)" class="object-cover"/></div>
								<div class="flex-1 ">
									<div class="font-weight-700 size-px-16 line-height-140 elipsis-2">{{ item.cartl_name }}</div>
									<div class="mtb-5 size-px-14 font-weight-500 line-height-140">{{ item.subscrp_plan_name }}</div>
									<div class="color-888 size-px-13 line-height-140">{{ item.subscrp_enddate.slice(0, 10) }} {{ $language.cartel_new.title_finish }} <!-- 종료 --></div>
								</div>
							</li>
						</ul>
						<Empty
							v-else
							class="pt-150"
							:text="text"
						></Empty>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Empty from "@/view/Layout/Empty";
import MypageSide from "@/view/Mypage/mypage-lnb";
export default {
	name: 'MypageSubscribeList'
	, components: {MypageSide, Empty}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: this.$language.notice.add
				, title: '구독 통합 내역'
				, not_footer: true
				, not_header: true
				, type: 'mypage'
				, bg_contents: 'bg-gray01'

			}
			, item_cartel: {

			}
			, item_member: {

			}
			, items_member: [
				{
					cartl_name: '닉네임'
					, subscrp_plan_name: '결제유형코드'
					, subscrp_enddate: '2023.05.31 종료'
					, subscrp_plan_img_url: ''
					, cartl_number: '31238667920b24d'
				}
				, {
					cartl_name: '1'
					, subscrp_plan_name: 'MAF'
					, subscrp_enddate: 'BC00100002'
					, coin_token_code: 'BC00200001'
					, subscrp_plan_img_url: ''
					, cartl_number: '31238667920b24d'
				}
				, {
					cartl_name: '1'
					, subscrp_plan_name: 'MAF'
					, subscrp_enddate: 'BC00100002'
					, coin_token_code: 'BC00200002'
					, subscrp_plan_img_url: ''
					, cartl_number: '31238667920b24d'
				}
			]
			, tab_type: 'in'
			, text:'구독 종료 내역이 없습니다.'
		}
	}
	, computed: {

	}
	, methods: {
		getInMember: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_mypage_subscribe_in_list
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})

				if(result.success){
					this.items_member = result.data.subscrp_unify_subscrp_particulars_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}


		, getOutMember: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_mypage_subscribe_out_list
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})

				if(result.success){
					this.items_member = result.data.subscrp_unify_subscrp_end_particulars_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, goBack: function(){
			this.$bus.$emit('goBack')
		}
		, onTab: function(type){
			this.tab_type = type
		}
		, toDetail: function(item){
			this.$bus.$emit('to', { name: 'CartelSubscribeList', params: { idx: item.cartl_number}})
		}
	}
	, async created() {
		this.$bus.$emit('onLoad', this.program)
		await this.getInMember()
	}
	, watch: {
		tab_type: {
			handler: function(call){
				if(call == 'in'){
					this.getInMember()
				}else{
					this.getOutMember()
				}
			}
		}
	}

}
</script>
<style>
.elipsis-2{
	width: 260px;
	display: -webkit-box;
	word-wrap: break-word;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	overflow: hidden;
}
</style>